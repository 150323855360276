@import "../../../../styles/_variables.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: $containerWidth;
  padding: 72px $containerPadding 120px;
  margin: 0 auto;

  * {
    margin: 0;
  }

  .title {
    width: 100%;
    font-size: 40px;
    margin-bottom: 80px;
  }

  .content {
    display: flex;
    align-items: start;
    justify-content: space-between;
    gap: 66px;
    flex-wrap: wrap;

    .item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      height: 330px;
      width: 100%;
      max-width: 330px;
      margin: 0 auto;

      svg {
        width: 40px;
        height: 40px;
      }

      .itemTitle {
        color: #162647;
        font-size: 24px;
        font-weight: 700;
        line-height: 115%;
      }

      .itemText {
        height: 105px;

        @media screen and (max-width: 400px) {
          height: auto;
        }
      }
    }
  }
}
