@import "../../../../styles/_variables.scss";

.background {
  display: flex;
  align-items: center;
  background: #f8fafc;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: $containerWidth;
    padding: 60px $containerPadding;
    margin: 0 auto;
  }

  .items {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 64px;
    flex-wrap: wrap;
    text-align: center;
    width: 100%;
  }

  .item {
    width: 100%;
    max-width: 200px;

    & svg {
      margin: 0 auto;
      width: 100%;
    }
  }

  .number {
    color: #333;
    font-size: 56px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1.5px;
  }

  .text {
    color: #d42087;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.5px;
  }

  .image {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
